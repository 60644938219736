@import '_variables.scss';
@import '_mixins.scss';
@import '_floating_label.scss';
@import '_vertical_layout.scss';

// Finally, bulma
@import 'lib/bulma/bulma.sass';

$solarized-magenta: #d33682;
$solarized-violet: #6c71c4;
$solarized-lightbg: #eee8d5;
$vue-black-blue: #34495e;

.sidebar ul li.active > a {
  color: $success;
}

.anchor span {
  color: $black;
}

h1 .anchor {
  display: flex;
  flex-direction: column;
  &:hover {
    text-decoration: none !important;
  }
  &::after {
    content: '';
    height: 4px;
    width: 60px;
    background-color: $success;
    margin-top: 0.75rem;
    border-radius: 3px;
  }
}

.markdown-section a {
  color: $success;
  &:hover {
    text-decoration: underline;
  }
}

.markdown-section {
  code {
    // solarized
    //background-color: $solarized-lightbg;
    color: $red;
    font-weight: 600;
  }
  pre code {
    font-weight: 500;
  }
  strong {
    color: $info;
  }

  p.tip {
    &::before {
      background-color: $red;
    }
    border-left: 5px solid $red;
    em {
      color: $red;
    }
  }
  p.warn {
    background-color: lighten($yellow, 10%);
  }
  i.fas,i.far,i.fab,i.fa {
    color: $info;
  }
  em {
    color: $info;
  }
}

.content blockquote {
  //background-color: #fdf6e3;
  color: $black;
  border-left: 5px solid $success;
  p {
    font-weight: unset;
  }
}

.content {
  // 15rem is an approx of the footer size, 9rem padding & 1.5rem logo+social btns & 3rem rest text
  min-height: calc(100vh - #{$navbar-height} - 15rem);
}

