$floating-label-size: 1em !default;
$floating-label-focused-ratio: 0.75 !default;
$floating-label-border-color: $light !default;
$floating-label-border-color-alt: $success !default;
$floating-label-transition: .2s !default;

%floating_label_empty_input {
  background: transparent;
  + label.label {
    position: absolute;
    top: calc(100% - 1.5 * #{$floating-label-size});
    z-index: -1;
    font-size: $floating-label-size;
  }
  color: transparent;
  @include placeholder {
    color: transparent;
  }
}

%floating_label_focused_input {
  + label.label {
    position: absolute;
    top: -$floating-label-size;
    color: $floating-label-border-color;
    font-size: $floating-label-size * $floating-label-focused-ratio;
  }
  &:focus {
    border-bottom: 1px solid $floating-label-border-color-alt !important;
    + label.label {
      color: $floating-label-border-color-alt;
    }
  }
}

div.field.floating-label {
  input, textarea {
    border: none !important;
    border-bottom: 1px solid $floating-label-border-color !important;
    box-shadow: none !important;
  }
  label.label {
    transition: $floating-label-transition;
  }
  margin-top: $floating-label-size * 1.5;
  @each $pref in 'input:not(:valid):not(:focus)', 'textarea:not(:valid):not(:focus)' {
    #{$pref} {
      @extend %floating_label_empty_input;
    }
  }
  @each $pref in 'input:focus', 'input:valid', 'textarea:focus', 'textarea:valid' {
    #{$pref} {
      @extend %floating_label_focused_input;
    }
  }
}
