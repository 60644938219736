@import "lib/bulma/sass/utilities/initial-variables";
@import "lib/bulma/sass/utilities/functions";

$white: #fff;
$white-invert: #4a4a4a;
$logoBlue: #4081A6;
$logoBlue-invert: #fff;
$black: #4a4a4a;
$black-invert: #fff;
$primary: #66cdcc;
$primary-invert: #fff;
$info: #336799;
$info-invert: #fff;
$light: #dedede;
$light-invert: $black;
$yellow: #fcb134;
$yellow-invert: #fff;
$navy: #001F3F;
$navy-invert: #fff;
$teal: #39CCCC;
$teal-invert: #fff;
$olive: #3D9970;
$olive-invert: #fff;
$lime: #01FF70;
$lime-invert: #fff;
$orange: #FF851B;
$orange-invert: $black;
$fuchsia: #F64C73;
$fuchsia-invert: #fff;
$purple: #605ca8;
$purple-invert: #fff;
$success: #5BB7C1;
$success-invert: #fff;
$scaiLogoRed: #ef384c;
$scaiLogoRed-invert: #fff;
$warning: #f9c700;
$warning-invert: $black;
$navbar-background-color: transparent;
$navbar-item-color: #fff;
$radius-rounded: 4px;
$grey-darker: $black;
$label-weight: 400;
$green: #5CB85C;
$green-invert: #fff;
$light-gray: #f0f0f0;
$light-gray-invert: $black;
$lighter-gray: #ececec;
$lighter-gray-invert: $black;
$navbar-scai-active-color: rgba(lighten($info, 6.5%), 0.9);
$navbar-item-hover-color: $white;
$navbar-item-hover-background-color: $navbar-scai-active-color;
$menu-item-active-color: $white;
$menu-item-active-background-color: $primary;
$tabs-link-active-color: $white;
$tabs-link-active-border-bottom-color: $info;
$tabs-boxed-link-active-background-color: $info;
$navbar-dropdown-background-color: $navbar-scai-active-color;
$navbar-dropdown-arrow: $white;
$navbar-dropdown-item-hover-color: $primary;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-boxed-shadow: 0 0 0 $light;
$navbar-dropdown-boxed-radius: 2px;
$navbar-dropdown-offset: 0px;
$navbar-dropdown-border-top: 1px solid $light !important;
$card-footer-border-top: 0px !important;
$default-transition: .5s;
$transparent-button-radius: 1em;
$ablue-orange: $orange;
$ablue-orange-invert: $black;
$ablue-blue: #283341;
$ablue-blue-invert: $white;
$azure-black: #1a1a1f;
$azure-blue: #2172b9;
$google-black: #3c4043;
$google-blue: #4285f4;
$footer-padding: 3rem 1.5rem;

@import "lib/bulma/sass/utilities/derived-variables.sass";
$navbar-height: 4rem;
$card-shadow: 0 2px 10px rgba($logoBlue, 0.7);
$addColors: (
  "logoBlue": ($logoBlue, $logoBlue-invert),
  "orange": ($orange, $orange-invert),
  "purple": ($purple, $purple-invert),
  "yellow": ($yellow, $yellow-invert),
  "fuchsia": ($fuchsia, $fuchsia-invert),
  "green": ($green, $green-invert),
  "light-gray" : ($light-gray, $light-gray-invert),
  "teal": ($teal, $teal-invert),
  "lighter-gray": ($lighter-gray, $lighter-gray-invert),
  "ablue-orange": ($ablue-orange, $ablue-orange-invert),
  "ablue-blue": ($ablue-blue, $ablue-blue-invert),
  "azure-black": ($azure-black, $white),
  "azure-blue": ($azure-blue, $white),
  "google-black": ($google-black, $white),
  "google-blue": ($google-blue, $white)
);
$colors: map-merge($colors, $addColors);
$scaiBackground: linear-gradient(-45deg, $primary 0%, $info 100%);
