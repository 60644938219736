@mixin placeholder {
  &::placeholder {
    @content;
  }
  @each $pref in '::-webkit-input', '::-moz', ':-moz', ':-ms-input' {
    &#{$pref}-placeholder {
      @content;
    }
  }
}

@mixin onfocus {
  &:hover, &:active, &:focus {
    @content;
  }
}

@mixin notonfocus {
  &:not(:hover):not(:active):not(:focus) {
    @content;
  }
}
