.main-timeline {
    background-color: $white;
    outline: none !important;
    &:active, &:focus {
        outline: none !important;
    }
    ul {
        padding-top: 20px;
        padding-bottom: 50px;
    }

    ul li {
        list-style-type: none;
        position: relative;
        width: 1px;
        margin: 0 auto;
        padding-top: 50px;
        //the line
        background: $black;
        &:before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 50%;
            width: 32px;
            height: 32px;
            border: $light;
            border-radius: 50%;
            background: $info;
            transform: translateX(-50%);
        }
    }

    ul li::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $info;
        //background: rgba(0,83,251,.5);
    }

    ul li div {
        position: relative;
        bottom: 0;
        width: 400px;
        border-radius: 1px;
        padding: 30px;
        color: $black;
        //background: rgba(17,149,247,.08);
        background: $white;
        border: 1px solid $light;
        box-shadow: 0 2px 3px 0 $light, 0 0 25px 0 $light;
        span {
            display: block;
            &.item-title {
                color: $black;
                font-family: 'Cabin', sans-serif;
                font-size: 1.2rem;
            }
            &.item-content {
                font-size: .9rem;
            }
            &.more {
                text-transform: uppercase;
                font-size: .8rem;
                padding-top: 20px;
                svg {
                    stroke-width: 1.4px;
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: 2px;
                }
                a {
                    transition: all .3s;
                    font-family: 'Cabin', sans-serif;
                    &:hover {
                        color: $black;
                        opacity: 0.7;
                    }
                }
            }
        }
        p {
            display: block;
            font-size: .85rem;
            font-weight: 500;
            padding: 10px 0;
        }
    }

    ul li div::before {
        content: '';
        position: absolute;
        bottom: 7px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: $black;
    }

    ul li:nth-child(odd) div {
        left: 45px;
    }

    ul li:nth-child(odd) div::before {
        left: -8px;
        border-width: 8px 8px 8px 0;
        border-color: transparent rgba(0,83,251,.5) transparent transparent;
    }

    ul li:nth-child(even) div {
        left: -439px;
    }

    ul li:nth-child(even) div::before {
        right: -8px;
        border-width: 8px 0 8px 8px;
        border-color: transparent transparent transparent rgba(0,83,251,.5);
    }

    /* EFFECTS
    –––––––––––––––––––––––––––––––––––––––––––––––––– */

    ul li::after {
        transition: background .5s ease-in-out;
    }

    ul li.in-view::after {
        background: $primary;
        //background: rgba(0,83,251,.5);
    }

    ul li div {
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease-in-out;
    }

    ul li:nth-child(odd) div {
        transform: translate3d(200px, 0, 0);
    }

    ul li:nth-child(even) div {
        transform: translate3d(-200px, 0, 0);
    }

    ul li.in-view div {
        transform: none;
        visibility: visible;
        opacity: 1;
    }


    /* GENERAL MEDIA QUERIES
    –––––––––––––––––––––––––––––––––––––––––––––––––– */

    @media screen and (max-width: 900px) {
        ul li div {
            width: 250px;
        }
        ul li:nth-child(even) div {
            left: -289px;
            /*250+45-6*/
        }
    }

    @media screen and (max-width: 600px) {
        ul li {
            margin-left: 20px;
        }
        ul li div {
            width: calc(100vw - 91px);
        }
        ul li:nth-child(even) div {
            left: 45px;
        }
        ul li:nth-child(even) div::before {
            left: -8px;
            border-width: 8px 8px 8px 0;
            border-color: transparent rgba(0,83,251,.5) transparent transparent;
        }
    }
}

.roadmap-end {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    top: -50px;
    left: 2px;
    img {
        height: 450px;
        display: block;
        margin: 0 auto;
    }
}
